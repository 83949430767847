import Home from "./componentes/home";
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import QuienesSomos from './componentes/quienesSomos';


function App() {
  return (
    <Router>
 
        <Route exact path="/" component={Home} />
        <Route path="/quienes-somos" component={QuienesSomos} />
      
    </Router>
  );
}

export default App;
