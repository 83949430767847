import React from 'react';
import { Link } from 'react-router-dom';
import '../css/NavBar.css';
import logo from '../imagenes/logoARV(chico).png';

const NavBar = () => {
  return (
    <nav className="navbar">
      <Link to="/">
        <img width="100px" src={logo} alt="" />
      </Link>
      <ul className="nav-links">
        <li>
          <Link to="/">Inicio</Link>
        </li>
        <li>
          <Link to="/quienes-somos">Quienes Somos</Link>
        </li>
        <li>
          <Link to="/contacto">Contacto</Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;