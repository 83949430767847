import React, { Component } from 'react';
import NavBar from '../commons/navBar';
import '../css/Home.css';
import imagen1 from '../imagenes/Mendoza.jpeg';
import imagen2 from '../imagenes/imagenBodega.png';
import Footer from '../commons/Footer';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export class Home extends Component {
  render() {
    return (
      <>
        <NavBar />
        <div className="home-container">
          <div className="content">
            <div className="image-container">
              <img src={imagen1} alt="Imagen 1" />
              <Link to="quienes-somos">ARV <br></br> Frutos Secos </Link>
            </div>
            <div className="image-container">
              <img src={imagen2} alt="Imagen 2" />
              <a target='blank' href="https://bodegaclaroscuro.com.ar">Bodega Claroscuro</a>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </>
    );
  }
}

export default Home;