import React, { useState, useEffect,useCallback } from 'react';
import NavBar from '../commons/navBar';
import Footer from '../commons/Footer';
import ImageViewer from 'react-simple-image-viewer';
import styled from 'styled-components';
import '../css/quienesSomos.css'
import imagen1 from '../imagenes/WhatsApp Image 2023-07-19 at 13.11.38 (1).jpeg';
import imagen2 from '../imagenes/WhatsApp Image 2023-07-19 at 13.11.38.jpeg';
import imagen3 from '../imagenes/WhatsApp Image 2023-07-19 at 13.11.39 (1).jpeg';
import imagen4 from '../imagenes/WhatsApp Image 2023-07-19 at 13.11.39.jpeg';
import imagen5 from '../imagenes/WhatsApp Image 2023-07-19 at 13.11.40.jpeg';
import imagen6 from '../imagenes/Mendoza.jpeg';
import backgroundImg from '../imagenes/Mendoza.jpeg'
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Establecer el alto mínimo de la página al 100% del viewport */
  position: relative; /* Asegurarse de que el componente 'Footer' se posicione correctamente */
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 67%;
  background-image: url(${backgroundImg});
  background-size: cover;
  filter: blur(8px);
  z-index: -1;
  
`;

const Content = styled.div`
  flex: 1; /* El contenido ocupará todo el espacio disponible, empujando el 'Footer' hacia abajo */
  position: relative; /* Asegurarse de que el 'Footer' se posicione correctamente */
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: ;
  flex-wrap: wrap;
  margin-top:100px;
`;

const Image = styled.img`
  width: 300px;
  height: auto;
  margin: 10px;
  border-radius: 5px;
  transition: transform 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`;

const TextContainer = styled.div`
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 1));
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-size: 16px;
  color: black;
  margin-top:200px;
  margin-bottom:100px;
`;
const LeftText = styled.div`
  flex: 1;
  float:;
  font-size:50px
`;

const RightText = styled.div`
  flex: 1;
  font-size:25px
`;
const DividerLine = styled.div`

  height: 10px;
  width: ${({ width }) => width}px;
  position: relative;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.3s ease;
  z-index: 1;
`;

const QuienesSomos = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [lineWidth, setLineWidth] = useState(0);
  const images = [imagen1, imagen2, imagen3, imagen4,imagen5,imagen6];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const handleScroll = useCallback(() => {
    // Calcula el ancho de la línea en función del desplazamiento vertical
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const contentHeight = document.getElementById('content').offsetHeight; // Ajusta el ID aquí según el elemento que contiene el contenido principal
    const dividerMaxWidth = 200; // Define el ancho máximo de la línea

    const distanceFromTop = scrollPosition + windowHeight;
    const maxScroll = contentHeight - windowHeight;
    const percentageScrolled = (distanceFromTop / maxScroll) * 100;
    const newLineWidth = (percentageScrolled / 100) * dividerMaxWidth;

    setLineWidth(newLineWidth);
  }, []);

  useEffect(() => {
    // Agrega el evento de scroll al montar el componente
    window.addEventListener('scroll', handleScroll);
    return () => {
      // Limpia el evento de scroll al desmontar el componente
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  return (
    <Wrapper>

      <NavBar />

      <Background />

      <Content id="content">
        <ImageContainer>
          {images.map((src, index) => (
            <Image
              src={src}
              onClick={() => openImageViewer(index)}
              key={index}
              alt=""
            />
          ))}
        </ImageContainer>
        <DividerLine width={lineWidth} /> {/* Ajusta el ancho de la línea según el estado 'lineWidth' */}

        <TextContainer>
       
          <LeftText>
            <h2 className='titulo '>Quienes <br></br>Somos</h2>

          </LeftText>

          <RightText>
            
            <p className='textoDerecha'>
            En nuestra empresa, la frescura y calidad de nuestros frutos secos están garantizadas, pues trabajamos con los proveedores más confiables y exigimos los más altos estándares de calidad en cada etapa del proceso. Explora nuestra gama de frutos secos y déjate seducir por los sabores naturales y la nutrición que ofrecen estos pequeños tesoros de la naturaleza. ¡Te aseguramos una experiencia gastronómica inigualable!
            </p>
          </RightText>
        </TextContainer>
      </Content>

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}

      <Footer />
    </Wrapper>
    
  );
};

export default QuienesSomos;