import React from 'react';
import '../css/Footer.css'; // Import the Footer styles

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-container">
      <div className="footer-content">
        <p className="footer-copyright">
          © {currentYear} ARV. Todos los derechos reservados.
        </p>
        <div className="footer-contact">
          <span className="footer-contact-label">Mail:</span>
          <a className="footer-email" href="mailto:info@arv.com.ar">
            info@arv.com.ar
          </a>
        </div>
        <div className="footer-contact">
          <span className="footer-contact-label">Teléfono:</span>
          <span className="footer-contact-info">+54 9 11 4394-9330</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;